<!-- eslint-disable vue/no-v-html -->
<template>
  <b-card
    no-body
    style="max-height: 420px;"
  >    
    <div v-if="isLoggedIn" class="like-icon-wrapper">
      <div v-if="isFavorite" class="like-icon favorite-icon" @click="$emit('remove-favorite', car._id)"> 
        <feather-icon icon="HeartIcon" size="20" color="white" />
      </div>

      <div v-else class="like-icon" @click="$emit('add-favorite', car._id)">
        <feather-icon icon="HeartIcon" size="20" color="white" />
      </div>
    </div>

    <car-image-display
      width="360"
      height="170"
      alt="Car"
      :src="getValueFromSource(car, 'car_images[0].image.path')"
      :hash="getValueFromSource(car, 'car_images[0].image.hash')"
    />
    
    <div class="p-1">
      <div>
        <h4 v-b-tooltip.hover class="text-dark ellipsis ellipsis-2" variant="text" :title="car.make_title">
          {{ car.car_model_year }} {{ car.brand_title }} {{ car.make_title }}
        </h4>
      </div>

      <b-card-text class="text-muted ellipsis-4 mt-1 mb-1 text-12">
        {{ extractTextContentFromHTML(car.description) }}
      </b-card-text>
      
      <div class="footer-div">
        <hr>
        <div class="d-flex align-items-center justify-content-between">
          <h6 class="text-primary">
            <small class="text-muted">
            Starting From
            </small>  ${{ formatMoney(car.rate) }}/<small class="text-muted">day</small>
          </h6>

          <b-button :to="{ name: 'rental-details', params: { id: car.id } }" variant="primary" size="sm">Interested</b-button>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import { BCol, BCard, BCardText, BLink, BButton, VBTooltip, BImg } from 'bootstrap-vue';

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import CarImageDisplay from './CarImageDisplay.vue';

export default {
  components: {
    BCol,
    BImg,
    BCard, 
    BLink,
    BButton,
    BCardText,

    CarImageDisplay,
    ToastificationContent
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  props: {
    car: {
      required: true,
      type: Object,
      default: () => ({})
    },
    isFavorite: {
      required: false,
      type: Boolean,
    },
    md: {
      type: Number,
      default: 2
    },
    containerClass: {
      type: String,
      default: 'pl-0'
    },
  },
}
</script>

<style lang="scss" scoped>
  .like-icon {
    padding: 5px;
    border-radius: 50%; 
  }

  .like-icon-wrapper {
    position: absolute;
    top: 5px; 
    right: 5px;
    padding: 5px;
    border-radius: 50%; 
    cursor: pointer;
    display: flex;
    align-items: center;
    z-index: 1 !important;
    justify-content: center;
  }

  .favorite-icon {
    background-color: #A1CB3A;
  }

  .footer-div {
    margin-top: auto;         
  }
</style>
