<template>
  <div class="d-block d-sm-none mb-3">
    <b-input-group class="input-group-merge">
      <b-input-group-prepend is-text>
        <feather-icon icon="SearchIcon" size="20" />
      </b-input-group-prepend>

      <b-form-input placeholder="Search for car make" size="lg" class="mb-0" />

      <b-input-group-append is-text class="custom-cls">
        <b-button v-b-toggle.sidebar-right class="btn-icon" variant="flat-success" size="sm">
          <feather-icon icon="SlidersIcon" size="20" />
        </b-button>
      </b-input-group-append>
    </b-input-group>

    <b-sidebar
      id="sidebar-right"
      bg-variant="white"
      right
      backdrop
      shadow
    >
      <search-filter-sidebar />
    </b-sidebar>
  </div>
</template>

<script>
  import {
    BRow,
    BCol,
    BImg,
    BLink,
    BCard,
    BButton,
    BOverlay,
    VBToggle,
    BSidebar,
    BCardText,
    BFormInput,
    BInputGroup,
    BFormGroup,
    BFormCheckbox,
    BInputGroupAppend,
    BFormCheckboxGroup,
    BInputGroupPrepend,
  } from 'bootstrap-vue';

  import Ripple from 'vue-ripple-directive'
  import SearchFilterSidebar from '@/@core/components/shared/SearchFilterSidebar.vue';

  export default {
    components: {
      BRow,
      BCol,
      BImg,
      BLink,
      BCard,
      BButton,
      BSidebar,
      BOverlay,
      BCardText,
      BFormInput,
      BFormGroup,
      BInputGroup,
      BFormCheckbox,
      BInputGroupAppend,
      BFormCheckboxGroup,
      BInputGroupPrepend,

      SearchFilterSidebar
    },
    directives: {
      Ripple,
      'b-toggle': VBToggle,
    },
  }
</script>

<style lang="scss">
  .custom-cls .input-group-text {
    padding: 0;
  }
</style>
