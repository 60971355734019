<template>
  <b-row class="col-12 col-md-9 col-lg-9 p-0 position-relative">
    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-center bg-primary flex-column py-4">
      <h1 class="font-weight-bolder text-white mb-1">
        Best offer of the day
      </h1>

      <b-card-text class="text-white">
        <span class="text-uppercase">{{ getValueFromSource(bestOffer, 'car.car_model_year') }} {{ getValueFromSource(bestOffer, 'car.car_brand.title') }} {{ getValueFromSource(bestOffer, 'car.car_make.title') }}</span> FOR ${{ formatMoney(getCarRate()) }}/<small class="text-dark">day</small>
      </b-card-text>

      <b-button :to="{ name: 'rental-details', params: { id: getValueFromSource(bestOffer, 'car._id') } }" class="btn d-block mb-1 bg-white text-primary" href="#" variant="outline-light" size="md">Rent Now</b-button>
    </b-col>

    <b-col cols="12" md="5" class="flex-grow" style="background-color: #799928;">
      <b-img
        class="car-of-the-day"
        :src="getValueFromSource(bestOffer, 'car.featured_image.path')"
        alt="Car of the Day"
      />
    </b-col>
  </b-row>
</template>

<script>
  import {
    BRow,
    BCol,
    BImg,
    BButton,
    BCardText
  } from 'bootstrap-vue';

  export default {
    components: {
      BRow,
      BCol,
      BImg,
      BButton,
      BCardText,
    },
    props: {
      bestOffer: {
        required: false,
        type: Object,
        default: null
      },
    },
    methods: {
      getCarRate() {
        const car_pricing = this.getValueFromSource(this.bestOffer, "car.car_pricing")
        const default_location = Array.isArray(car_pricing) ? car_pricing.find(el => el.default_location) : { amount: 0 }
        return default_location ? default_location.amount : 0
      },
      getFeaturedImage() {
        const car_images = this.getValueFromSource(this.bestOffer, 'car.car_images')
        const has_featured_image = car_images.find(el => el.is_featured_image)
        const first_image_path = this.getValueFromSource(this.bestOffer, 'car.car_images[0].image.path')

        return has_featured_image ? this.getValueFromSource(has_featured_image, 'image.path') : first_image_path
      }
    }
  }
</script>

<style lang="scss" scoped></style>
