<template>
  <b-overlay :show="loadingCars">
    <section v-if="bestOfferCar" class="car-of-the-day-wrapper">
      <div
        class="d-flex align-items-center mt-5 justify-content-center my-5"
      >
        <best-car-of-the-day-ad 
          :best-offer="bestOfferCar"
        />
      </div>
    </section>

    <section v-else class="mb-5"></section>

    <section ref="carsDisplaySection">
      <div v-if="['xs', 'sm'].includes(currentBreakPoint)" class="px-3">
        <mobile-view-search-input />
      </div>

      <b-row class="px-3">
        <b-col v-if="!['xs', 'sm'].includes(currentBreakPoint)" cols="12" xl="2" lg="3" md="4" class="p-0 d-none d-md-block">
          <search-filter-sidebar />
        </b-col>

        <b-col cols="12" xl="10" lg="9" md="6" sm="12">
          <div no-body>
            <b-row class="match-height">
              <b-col v-for="(car, i) in carsAvailableForRental" :key="i" cols="12" md="3">
                <car-display-card 
                  :car="car" 
                  :is-favorite="isFavoriteCar(car._id)"
                  @add-favorite="$car_id => onAddFavorite($car_id)"
                  @remove-favorite="$car_id => onRemoveFavorite($car_id)"
                />
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </section>

    <div v-if="loadingCars" class="text-center my-4">
      <b-spinner label="LoadingCars..." />
    </div>
  </b-overlay>
</template>

<script>
import {
  BRow,
  BCol,
  BImg,
  BLink,
  BCard,
  BButton,
  BOverlay,
  BCardText,
  BFormInput,
  BPagination,
  BSpinner,
} from "bootstrap-vue";

import CarsMixin from "@/@core/mixins/cars";
import CarDisplayCard from "@/@core/components/shared/CarDisplayCard.vue";
import BestCarOfTheDayAd from "@/@core/components/shared/BestCarOfTheDayAd.vue";
import SearchFilterSidebar from "@/@core/components/shared/SearchFilterSidebar.vue";
import MobileViewSearchInput from "@/@core/components/shared/MobileViewSearchInput.vue";
import { debounce } from "lodash";

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BLink,
    BCard,
    BButton,
    BOverlay,
    BCardText,
    BFormInput,
    BPagination,
    BSpinner,
    CarDisplayCard,
    BestCarOfTheDayAd,
    SearchFilterSidebar,
    MobileViewSearchInput,
  },
  mixins: [CarsMixin],
  data() {
    return {
      debounceFetchAllData: () => {},
      debounceHandleScroll: () => {},
    };
  },
  computed: {
    perPage: {
      get() {
        return this.$store.getters[`navigation/recordsPerPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_RECORDS_PER_PAGE`, value)
      }
    },
    currentPage: {
      get() {
        return this.$store.getters[`navigation/currentPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_CURRENT_PAGE_NUMBER`, value)
      }
    },
    totalRecords: {
      get(){
        return this.$store.getters[`navigation/totalRecords`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_TOTAL_RECORDS`, value)
      }
    },
  },
  watch: {
    perPage: {
      handler() {
        this.currentPage = 1;
        this.debounceFetchAllData();
      },
      immediate: false,
    },
    filters: {
      deep: true,
      handler() {
        this.fetchRentalCars();
      }
    }
  },
  mounted() {
    this.debounceHandleScroll = debounce(this.handleScroll, 200);
    window.addEventListener("scroll", this.debounceHandleScroll);
    this.fetchBestOffers()
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.debounceHandleScroll);
  },
  created() {
    this.debounceFetchAllData = debounce(this.fetchRentalCars, 500);
  },
  methods: {
    handleScroll() {
      const { carsDisplaySection } = this.$refs
      if (!carsDisplaySection) return;

      const { bottom } = carsDisplaySection.getBoundingClientRect();
      const viewportHeight = window.innerHeight;

      if (bottom <= viewportHeight && this.hasMoreCars) {
        this.currentPage += 1; 
        this.fetchRentalCars();
      }
    },
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/app-home.scss";
</style>
